import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import classNames from "classnames";
import RHArrowIcon from "icons/RHArrowIcon";
import React, { FC } from "react";
import { getAnalyticsAttrsFromProps } from "utils/analytics";
import memoize from "utils/memoize";

export interface TailwindButtonExtProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  endIcon?: React.ReactNode;
  endIconSm?: boolean;
  endIconMd?: boolean;
  endIconStroke?: boolean;
  endIconStrokeSm?: boolean;
  endIconStrokeMd?: boolean;
  text?: string;
  textSm?: string;
  textMd?: string;
  hide?: boolean;
  hideSm?: boolean;
  hideMd?: boolean;
  onclick?: Function;
  startIcon?: React.ReactNode;
  startIconSm?: boolean;
  startIconMd?: boolean;
  variant?: "text" | "contained" | "outlined";
  variantSm?: "text" | "contained" | "outlined";
  variantMd?: "text" | "contained" | "outlined";
  colorSm?: string;
  colorMd?: string;
  fullWidthSm?: boolean;
  hideClassNames?: string;
  width?: string;
  widthSm?: string;
  widthMd?: string;
  height?: string;
  heightSm?: string;
  heightMd?: string;
  padding?: string;
  paddingSm?: string;
  paddingMd?: string;
  align?: string;
  alignMd?: string;
  alignSm?: string;
  textWrap?: boolean;
  textWrapSm?: boolean;
  textWrapMd?: boolean;
  startIconSpacing?: number;
  endIconSpacing?: number;
  font?: string;
  fontMd?: string;
  fontSm?: string;
  fontSize?: number;
  fontSizeMd?: number;
  fontSizeSm?: number;
}

const TailwindButton: React.FC<TailwindButtonExtProps> = props => {
  const {
    endIcon = null,
    endIconSm = null,
    endIconMd = null,
    endIconStroke = null,
    endIconStrokeSm = null,
    endIconStrokeMd = null,
    startIcon = null,
    startIconSm = null,
    startIconMd = null,
    hideMd,
    hideSm,
    variant = "text",
    variantSm = "text",
    variantMd = "text",
    align = "center",
    alignMd = "center",
    alignSm = "center",
    children,
    style
  } = props;

  const analyticsAttrs = getAnalyticsAttrsFromProps(props);

  let baseClass =
    "transition-all duration-1000 ease-linear whitespace-nowrap min-w-auto";
  let rootClass = classNames(baseClass, props.hideClassNames);
  let rootClassMd = rootClass;
  let rootClassSm = rootClass;

  const variantClasses = {
    text: "text-left whitespace-normal inline",
    contained: "bg-primary text-white",
    outlined: "border border-primary text-primary",
    underline:
      "border-b  border-black font-[inherit] text-black not-italic leading-[120%] tracking-wider uppercase"
  };

  if (variantMd === "text" && alignMd === "left") {
    rootClassMd = classNames(baseClass, "text-left whitespace-normal inline");
  } else if (variantMd === "text" && alignMd === "right") {
    rootClassMd = classNames(baseClass, "text-right whitespace-normal inline");
  } else if (variantMd === "text" && alignMd === "center") {
    rootClassMd = classNames(baseClass, "text-center whitespace-normal inline");
  }

  if (variantSm === "text" && alignSm === "left") {
    rootClassSm = classNames(baseClass, "text-left whitespace-normal inline");
  } else if (variantSm === "text" && alignSm === "right") {
    rootClassSm = classNames(baseClass, "text-right whitespace-normal inline");
  } else if (variantSm === "text" && alignSm === "center") {
    rootClassSm = classNames(baseClass, "text-center whitespace-normal inline");
  }

  if (variant === "text" && align === "left") {
    rootClass = classNames(baseClass, "text-left whitespace-normal inline");
  } else if (variant === "text" && align === "right") {
    rootClass = classNames(baseClass, "text-right whitespace-normal inline");
  } else if (variant === "text" && align === "center") {
    rootClass = classNames(baseClass, "text-center whitespace-normal inline");
  }

  interface ButtonContentProps {
    isSm?: boolean;
    isMd?: boolean;
  }
  const ButtonContent: FC<ButtonContentProps> = ({ isSm, isMd }) => (
    <>
      {startIconSm && isSm ? <ArrowRightAltIcon /> : null}
      {startIconMd && isMd ? <ArrowRightAltIcon /> : null}
      {startIcon && !isSm && !isMd ? <ArrowRightAltIcon /> : null}
      <span className={`${variantClasses[variant]}`}>{children}</span>
      {endIconSm && isSm ? (
        <RHArrowIcon
          className="!text-[86%] !h-4 !w-4 ml-1"
          strokeColor={endIconStrokeSm ? "currentColor" : undefined}
          style={{ overflow: endIconStrokeSm ? "visible" : undefined }}
        />
      ) : null}
      {endIconMd && isMd ? (
        <RHArrowIcon
          className="!text-[86%] !h-4 !w-4 ml-1"
          strokeColor={endIconStrokeMd ? "currentColor" : undefined}
          style={{ overflow: endIconStrokeMd ? "visible" : undefined }}
        />
      ) : null}
      {endIcon && !isSm && !isMd ? (
        <RHArrowIcon
          className="!text-[86%] !h-4 !w-4 ml-1"
          strokeColor={endIconStroke ? "currentColor" : undefined}
          style={{ overflow: endIconStroke ? "visible" : undefined }}
        />
      ) : null}
    </>
  );

  return (
    <button
      {...analyticsAttrs}
      className={
        !hideSm ? rootClassSm : !hideMd && !hideSm ? rootClassMd : rootClass
      }
      style={{ ...style }}
      {...props}
    >
      <ButtonContent />
    </button>
  );
};

TailwindButton.defaultProps = {
  textWrap: true,
  textWrapSm: true,
  textWrapMd: true
};

export default memoize(TailwindButton);
